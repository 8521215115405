import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import Layout from "../components/layout"
import { Link } from "gatsby"

export default function PostPage({ data }) {
  const {
    id,
    body,
    frontmatter: { title, date },
  } = data.mdx;
  var nextNode = null, prevNode = null;
  data.allMdx.edges.forEach(({node, next, previous}) => {
      if(node.id === id) {
        nextNode = next;
        prevNode = previous;
      }
  });


  return (
    <Layout>
      <article className="blog-post-wrapper">
        <h1>
          {title}
        </h1>
        <MDXRenderer>{body}</MDXRenderer>
        <div className="navigation-links">
          { prevNode ? <Link className="link-prev" to={`/${prevNode.slug}`}>{prevNode.frontmatter.title}</Link>: null }
          { nextNode ? <Link className="link-next" to={`/${nextNode.slug}`}>{nextNode.frontmatter.title}</Link>: null }
        </div>
      </article>
    </Layout>
  );
}

export const query = graphql`
  query POST_BY_SLUG($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      frontmatter {
        date(formatString: "D MMMM YYYY", locale: "sv")
        title
      }
    }
    allMdx(sort: {order: DESC, fields: frontmatter___date}) {
      edges {
        next {
          slug
          frontmatter {
            title
          }
        }
        previous {
          slug
          frontmatter {
            title
          }
        }
        node {
          id
        }
      }
    }
  }
`;
